<template>
  <div
    :style="`max-height: min-content; font-size: ${this.pageSettings.fontSize}`"
    class="q-ma-sm"
  >
    <div class="text-left" id="recibo">
      <q-avatar rounded size="70px">
        <img :src="loja?.LOGO ? loja.LOGO : defaultLogo" />
      </q-avatar>
      <br />
      <br />
      <strong>
        {{ loja?.NOME.toUpperCase() }}<br />
        {{ loja?.WHATSAPP }}<br />
        {{ loja?.CPF_CNPJ }}
      </strong>
      <br />
      <br />
      <strong>RECIBO DE VENDA </strong>
      <br />
      DOCUMENTO NAO FISCAL! <br />
      <br />

      <strong>DADOS DO RECIBO</strong> <br />
      DATA DO RECEBIMENTO: {{ dateFormat(recibo.DATA) }}
      <br />
      <div v-if="venda?.ID_CLIENTE">
        CLIENTE:
        <strong>
          {{ venda?.CLIENTE?.toUpperCase() }}
        </strong>
        <q-item-label>{{ venda?.FANTASIA }}</q-item-label>
      </div>
      <div v-else>
        Cliente:
        <strong>
          {{ venda?.CLIENTE?.toUpperCase() }}
        </strong>
      </div>
      <br />
      Nº PARCELA: {{ recibo.PARCELA }}/{{ venda?.PARCELAS || 1 }} <br />
      VALOR PAGO: <strong> R$ {{ recibo.VALOR }} </strong>
      <span
        v-if="parseFloat(recibo.VALOR) < parseFloat(venda?.VALOR_PARCELA, 0)"
      >
        (PAGO PARCIALMENTE!)</span
      >

      <br />
      <br />
      <strong>PRODUTOS DA VENDA</strong>
      <br />
      <br />

      <div v-if="loja?.TAMANHO_IMPRESSAO !== 'A4'">
        QTD | PROD. | VAL. UNIT. | TOTAL <br />
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        <div v-for="produto in venda?.produtosVendas" :key="produto">
          {{
            produto.FRACIONADO
              ? parseFloat(produto.QUANTIDADE, 0).toFixed(3)
              : parseInt(produto.QUANTIDADE)
          }}x {{ produto.NOME.toUpperCase() }} R${{ produto.VALOR }} = R${{
            produto.TOTAL
          }}
        </div>
      </div>
      <q-table
        :rows="venda.produtosVendas"
        flat
        :columns="columns"
        hide-bottom
        :pagination="pagination"
        v-else
      />
      <br />
      <strong class="text-positive"
        >+SUB TOTAL: R${{ venda?.SUB_TOTAL }}</strong
      >
      <br />
      <strong class="text-negative">-DESCONTO: R${{ venda?.DESCONTO }}</strong>
      <br />
      <strong>=TOTAL: R${{ venda?.TOTAL }}</strong> <br />
      <span>FORMA DE PAGAMENTO: {{ venda?.TIPO_PAGAMENTO }}</span> <br />
      <div
        v-if="
          venda?.TIPO_PAGAMENTO === 'CREDIÁRIO' ||
          venda?.TIPO_PAGAMENTO === 'DUPLICATA'
        "
      >
        PARCELAS: {{ venda?.PARCELAS || 1 }} | VALOR PARCELA: R${{
          venda.VALOR_PARCELA
        }}
      </div>
      <br />
      <div class="text-center q-mt-lg">
        <p>{{ recibo.DATA }}{{ recibo.ID }}<br /></p>
        MINI PDV - CONTROLE DE VENDAS E ESTOQUE
      </div>
    </div>
  </div>
</template>
<script>
import receitasApi from "src/api/vendas/vendas.api";
import formaters from "../../../helpers/formaters";
import vendasApi from "src/api/vendas/vendas.api";
export default {
  name: "ReciboPage",
  data() {
    return {
      recibo: {},
      logoPath: "",
      loja: JSON.parse(localStorage.getItem("loja")),
      columns: [
        {
          name: "codigo",
          label: "Cod",
          field: "CODIGO_PRODUTO",
          align: "left",
        },
        {
          name: "nome",
          label: "Produto",
          field: "NOME",
          align: "left",
        },
        {
          name: "quantidade",
          label: "Qtd",
          field: "QUANTIDADE",
          align: "left",
        },
        {
          name: "valor",
          label: "Valor R$",
          field: "VALOR",
          align: "left",
        },
        {
          name: "total",
          label: "Total R$",
          field: "TOTAL",
          align: "left",
        },
      ],
      pagination: {
        rowsPerPage: 0,
      },
      pageSettings: {
        fontSize: "14px",
      },
      tipo: "",
      venda: {},
    };
  },
  async created() {
    this.recibo = await JSON.parse(this.$route.query.data);
    await this.getVenda();
    await this.getProdutosVenda();
    await this.getLoja();

    this.setFontSize();
    this.printPage();
  },
  async mounted() {},
  computed: {
    defaultLogo() {
      return require("../../../../public/icons/icon-512x512.png");
    },
  },
  methods: {
    printPage() {
      window.print();
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
    async getLoja() {
      this.loja = await JSON.parse(localStorage.getItem("loja"));
    },
    setFontSize() {
      switch (this.loja?.TAMANHO_IMPRESSAO) {
        case "58mm":
          this.pageSettings.fontSize = "9px";
          break;

        case "80mm":
          this.pageSettings.fontSize = "11px";
          break;

        default:
          this.pageSettings.fontSize = "14px";
          break;
      }
    },
    async getVenda() {
      await vendasApi.getByHash("vendas", this.recibo.HASH).then((response) => {
        this.venda = response.data[0];
      });
    },
    async getProdutosVenda() {
      await receitasApi
        .getByHash("produtos_vendas", this.recibo.HASH)
        .then((response) => {
          this.venda.produtosVendas = response.data || [];
        });
    },
  },
};
</script>

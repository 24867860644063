<template>
  <div
    :style="`max-height: min-content; font-size: ${this.pageSettings.fontSize}`"
    class="q-ma-sm"
  >
    <div class="text-left" id="recibo">
      <q-avatar rounded size="70px">
        <img :src="loja?.LOGO ? loja.LOGO : defaultLogo" />
      </q-avatar>
      <br />
      <br />
      <strong>
        {{ loja?.NOME }}<br />
        {{ loja?.WHATSAPP }}<br />
        {{ loja?.CPF_CNPJ }}
      </strong>
      <br />
      <br />
      <strong>RECIBO DE ENTRADA </strong>
      <br />
      DOCUMENTO NAO FISCAL! <br />
      <br />
      DATA DA ENTRADA: {{ dateFormat(recibo.DATA) }}
      <br />
      <div v-if="recibo?.ID_FORNECEDOR">
        FORNECEDOR: {{ recibo?.NOME?.toUpperCase() }}
      </div>
      <div v-if="loja?.TAMANHO_IMPRESSAO !== 'A4'">
        QTD PROD. VL UNT TOTAL <br />
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        <div v-for="produto in recibo.produtosEntrada" :key="produto">
          {{ produto.QUANTIDADE }}x {{ produto.NOME }} R${{ produto.CUSTO }} =
          R${{ produto.TOTAL }}
        </div>
      </div>
      <q-table
        :rows="recibo.produtosEntrada"
        flat
        :columns="columns"
        hide-bottom
        :pagination="pagination"
        v-else
      />
      <br />
      <strong class="text-positive"
        >+SUB TOTAL: R${{ recibo.SUB_TOTAL }}</strong
      >
      <br />
      <strong class="text-negative">+FRETE: R${{ recibo.FRETE }}</strong>
      <br />
      <strong class="text-positive">-DESCONTO: R${{ recibo.DESCONTO }}</strong>
      <br />
      <strong>=TOTAL: R${{ recibo.TOTAL }}</strong> <br />
      <span>FORMA DE PAGAMENTO: {{ recibo.TIPO_PAGAMENTO }}</span>
       <br />
      <div class="text-center">
        <p>{{ recibo.DATA }}{{ recibo.ID }}<br /></p>
        MINI PDV - CONTROLE DE VENDAS E ESTOQUE
      </div>
    </div>
  </div>
</template>
<script>
import formaters from "../../../helpers/formaters";
export default {
  name: "ReciboPage",
  data() {
    return {
      recibo: {},
      logoPath: "",
      loja: JSON.parse(localStorage.getItem("loja")),
      pagination: {
        rowsPerPage: 0,
      },
      pageSettings: {
        fontSize: "14px",
      },
      tipo: "",
          columns: [
        {
          name: "codigo",
          label: "Cod",
          field: "CODIGO_PRODUTO",
          align: "left",
        },
        {
          name: "nome",
          label: "Produto",
          field: "NOME",
          align: "left",
        },
        {
          name: "quantidade",
          label: "Qtd",
          field: "QUANTIDADE",
          align: "left",
        },
        {
          name: "valor",
          label: "Valor R$",
          field: "VALOR",
          align: "left",
        },
        {
          name: "total",
          label: "Total R$",
          field: "TOTAL",
          align: "left",
        },
      ],
    };
  },
  async created() {
    await this.getLoja();
    this.recibo = JSON.parse(this.$route.query.data);
    this.printPage();
  },
  computed: {
    defaultLogo() {
      return require("../../../../public/icons/icon-512x512.png");
    },
  },
  methods: {
    printPage() {
      window.print();
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
    async getLoja() {
      this.loja = await JSON.parse(localStorage.getItem("loja"));
    },
    setFontSize() {
      switch (this.loja?.TAMANHO_IMPRESSAO) {
        case "58mm":
          this.pageSettings.fontSize = "9px";
          break;

        case "80mm":
          this.pageSettings.fontSize = "11px";
          break;

        default:
          this.pageSettings.fontSize = "14px";
          break;
      }
    },
  },
};
</script>

import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/tenants`;
import helpers from "../../helpers/session";
const user = helpers.getCurrentUser();
const headersJson = {
  Authorization: "Basic bWluaXBkdi5saW5lYXNmdC5jb20uYnI6QXBwQDIwMjA=",
  "Content-Type": "application/json",
  "X-Tenant": user?.tenant,
};

export default {
  get: async (table) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getById: async (table, id) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/id/${id}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getById: async (table, id) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/id/${id}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getByNome: async (table, nome) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/nome/${nome}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getByEmail: async (table="usuarios", email) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/email/${email}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  insert: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/insert`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  update: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/update`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  remove: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/remove`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
};
